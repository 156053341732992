.hero-container {
    filter: grayscale(55%);
    background: #1DC198;
    height: 100vh;
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    object-fit: contain;
    font-family: 'Roboto', sans-serif;
    background-image: url('/images/foto-portada.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.content-container {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0px;
    width: 100vw;
    height: 100vh;
}

.text-container {
    width: 50%;
    margin-left: 50;
    text-align: left;
}

.text-container > h1 {
    flex: 1;
    color: white;
    font-size: 60px;
    margin-top: 1px;
    margin-left: 50px;
}

.text-container > p {
    text-align: left;
    flex: 1;
    margin-left: 50px;
    margin-top: 20px;
    line-height: 32px;
    color: white;
    font-size: 22px;
    font-weight: 100;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
    margin-top: 32px;
    margin-left: 50px;
    text-align: left;
    width: 40%;
}

.hero-btns .btn {
    margin: 6px;
}

.fa-play-circle {
    margin-left: 4px;
}

.info-container {
    font-size: large;
    color: #fff;
    height: 20%;
    background: transparent;
    position: relative;
    z-index: 1;
}

@media screen and (max-width: 960px) {
    .hero-container > h1 {
        font-size: 50px;
        margin-top: -50px;
    }
}

@media screen and (max-width: 768px) {
    .hero-container {
        position: relative;
        background-image: url("/images/foto-portada.jpg");
        background-repeat: no-repeat;
        background-position: center top;
        position: relative;
        height: 90vh;
        width: auto;
    }

    .text-container {
        margin-left: 10px;
        width: 80%;
    }

    .text-container > h1 {
        font-size: 50px;
        margin-top: 0;
        height: auto;
    }

    .text-container > p {
        font-size: 20px;
        margin-top: 10px;
        color: black;
        font-weight: 100;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
        
    }

    .hero-btns {
        width: 100%;
        text-decoration: none;
    }
 
}
