.about-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: linear-gradient(0deg, rgba(155, 244, 234, 1) 74%, rgba(98, 230, 220, 1) 58%, rgba(49, 208, 200, 1) 36%);

}

.about-content-container {
  display: flex;
  align-items: center;
}

.about-content-container > img {
  width: 55vh;
  height: auto;
}

.about-text-container {
  width: 45vh;
  height: auto;
  margin-left: 20px;
  line-height: 160%;
  padding: 3%;
}
.about-text-container > h3{
  padding-bottom: 10%;
  font-weight: 600;
  font-size: 1.8rem;
}
.about-text-container > p{
  padding-bottom: 10%;
}

/* Ajustes para pantallas más pequeñas (responsivo) */
@media screen and (max-width: 768px) {
  .about-content-container {
    flex-direction: column-reverse;
    align-items: center;
  }

  .about-text-container {
    max-width: 90%; /* Ocupa todo el ancho en pantallas más pequeñas */
    margin-left: 0;
    margin-top: 20px;
    padding: 7%;
  }
  .about-text-container > h3{
      padding-bottom: 3%;

  }
  .about-content-container img {
    width: 90%;
    height: auto;
  }
  .about-text-container{
    width: 90%;
  }
  
}
