 .info-container {
  background: linear-gradient(180deg, rgba(18, 115, 115, 1) 0%, rgba(18, 158, 156, 1) 61%);
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
}

.info-content-container {
  display: flex;
  justify-content: space-around; 
  width: 80%; 
}
.info-item {
  text-align: center;
  flex: 1; 
  margin: 0 20px;
}
.info-item>h3{
  padding-bottom: 6%;
}
.info-item > p{
  text-align:justify;
  flex: 1; 
  margin: 0 20px;
}

.info-image {
  width: 90%; 
  height: auto; 
  overflow: hidden;
  background-color: #f0f0f0;
}



.info-text {
  flex: 1;
  font-size: 40px;
  color: #000;
}

.info-item > img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;

 width: 55%;
  
}



.info-text > p {
  font-size: 25px;
  font-weight: 100;
}

@media screen and (max-width: 769px){
  .info-container {
    padding-top: 5%;
    height: 80vh;
    padding: 5%;
  }
  .info-content-container{
    width: 90%;

  }

  .info-item{
    padding-top: 5%;
  }
  .info-item > h3,p{
    font-size: 1rem;
  }
  

  .info-item > img{
    padding-bottom: 10%;
    height: 20%;
    width: auto;
    
  }

}


