.services-container {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 10%;
    background: linear-gradient(7deg, rgba(18, 115, 115, 1) 0%, rgba(18, 158, 156, 1) 61%);
  }
  
  .services-wrapper {
    max-width: 1000px;
    margin: 0;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: 16px;
    padding: 0 20px;
  }
  
  .services-card {
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    height: 280px;
    width: auto;
    padding: 35px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;
  }
  
  .services-card:hover {
    transform: scale(1.02);
    cursor: pointer;
  }
  
  .services-card > img {
    height: 160px;
    width: auto;
    margin-bottom: 10px;
  }
  
  .services-container > h2 {
    font-size: 2rem;
    color: #fff;
    
    
    padding: 5%;
    font-weight: 400;
    text-align: center;
  }

  
  .services-card > h3 {
    font-size: 1.2rem;
    margin-top: 10px;
  }
  
  .services-card > p {
    font-size: 1rem;
    font-weight: 100;
    margin-top: 10px;
  }
  
  @media screen and (min-width: 768px) {
    .services-container {
      height: auto;
    }
    .services-wrapper {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media screen and (min-width: 1000px) {
    .services-container {
      height: auto;
    }
    .services-wrapper {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      padding: 0 50px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .services-container {
      height: auto;
    }
    .services-container > h2 {
      font-size: 1.5rem;
    }
    .services-wrapper {
      padding: 0 10px;
    }
  }
  