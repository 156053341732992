.location-container {
  display: flex;
  flex-direction: column; /* Cambia a dirección de columna en vista móvil */
  align-items: center;
  position: relative;
  background: linear-gradient(0deg, rgba(49, 208, 200, 1) 74%, rgba(98, 230, 220, 1) 58%, rgba(155, 244, 234, 1) 36%);
  font-family: 'Roboto', sans-serif;
  padding-top: 3%;
}

.location-content-container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  text-align: center;
  width: 80%; 
  padding-bottom: 4%;
  
}

.location-content-container > img {
  display: inline-block;
  float: none;
  padding: 10px;
  width: auto;
  height: 400px;
}


.location-content-container > p {
  font-weight: 500;
}



.location-container > h2{
  font-size: 2rem;
  font-weight: 600;
  width: 70%;
  text-align: center;
}


.btn-mobile {
  margin-top: 20px; 
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;

}

.btns{
  width: 20%;
}

@media screen and (max-width: 768px) {
  .location-content-container > img {
    width: 90%;
    height: auto;
  }
  .location-container > h2{
    font-size: 1.8rem;
    width: 80%;
    
  }
}


  
