@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,500,300,700);
.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.products,
.contact-us,
.sign-up,
.marketing,
.consulting {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.home {
  background-image: url('/images/img-1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.services {
  background-image: url('/images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url('/images/img-3.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.contact-us {
  /* background-image: url('./images/img-4.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url('/images/img-7.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.marketing {
  background-image: url('/images/img-6.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.consulting {
  background-image: url('/images/img-5.jpg');
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.btn-wsp{
	position:fixed;
	width:80px;
	height:80px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;

}
.my-float{
	margin-top:25px;
}


.navbar {
  background: linear-gradient(90deg, rgba(18,19,23,1) 0%, rgba(50,59,66,1) 61%);
  
  height: 15vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 1.2rem;
} 

img{
  margin-top: 5px;
  height: 50px;

}
.logo-container {
  color: #fff;
  margin-left: 20px;
  cursor: pointer;
  font-size: 2rem;
  display: flex; 
  height: 100%;

}
.navbar-container{
  display: flex;
}
  
.nav-menu {
  display: flex;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  width: 70vw;
  justify-content:center;
  text-align: center;

}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
  font-size: 15px;
  color: #fff;
}


.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  text-align: center;
  font-weight: 700;
}


.nav-links:hover {
  background-color: white;
  color: black;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0; .navbar {
      background: linear-gradient(90deg, rgba(18,19,23,1) 0%, rgba(50,59,66,1) 61%);
      
      height: 15vh;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      font-size: 1.2rem;
    } 
    
    img{
      margin-top: 5px;
      height: 50px;
  
    }
    .logo-container {
      color: #fff;
      margin-left: 20px;
      cursor: pointer;
      font-size: 2rem;
      display: flex; 
      height: 100%;
  
    }
    .navbar-container{
      display: flex;
    }
      
    .nav-menu {
      display: flex;
      grid-template-columns: repeat(5, auto);
      grid-gap: 10px;
      list-style: none;
      width: 70vw;
      justify-content: flex-end;
      text-align: center;
    
    }


    
    .nav-item {
      display: flex;
      align-items: center;
      height: 80px;
      font-size: 15px;
    }
    
    
    .nav-links {
      color: white;
      text-decoration: none;
      padding: 0.5rem 1rem;
      text-align: center;
    }
    
    .nav-links:hover {
      background-color: white;
      color: black;
      border-radius: 4px;
      transition: all 0.2s ease-out;
    }
    
    .fa-bars {
      color: #fff;
    }
    
    .nav-links-mobile {
      display: none;
    }
    
    .menu-icon {
      display: none;
    }


    @media screen and (max-width: 960px) {
      .NavbarItems {
        position: relative;
      }
    
      .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
      }
    
      .nav-menu.active {
        background: #242222;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
      }
    
      .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
      }
    
    
      .nav-links:hover {
        background-color:#129e9c 61%;
        border-radius: 0;
      }
    
      .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
      }
    
      .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.2rem;
        cursor: pointer;
      }
    
      .fa-times {
        color: #fff;
        font-size: 2rem;
      }
    
      .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        background: #1888ff;
        text-decoration: none;
        color: #fff;
        font-size: 1.5rem;
      
      }
  
    .nav-item{
      font-size: 100%;
    
    }

    
      .nav-links-mobile:hover {
        background: #fff;
        color: #129e9c 61%;
        transition: 250ms;
      }
    
      button {
        display: none;
      }
    }

    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #1888ff;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.2rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  
  }

.nav-item{
  font-size: 100%;
}


  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }

  button {
    display: none;
  }
} 
.btn {
  border-radius: 50px;
  white-space: nowrap;
  outline: none;
  border: none;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

.btn--primary {
  background-color: var(--primary);
  color: #242424;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: #242424;
  color: #fff;
  padding: 8px 20px; /* Ajusta estos valores según tus necesidades */
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  color: #242424;
  transition: 250ms;
}



/* :root {
    --primary: #fff;

  }
  
  .btn {
    
    border-radius: 50px;
    white-space: nowrap;
    outline: none;
    border: none;
    
    
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    
   
  }
  
  .btn--primary {

    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
  }
  
  .btn--outline {
    background-color: #242424;
    color: #fff;
    padding: 8px 20px;
   
    transition: all 0.3s ease-out;
  }
  
  .btn--medium {
    padding: 8px 20px;
    font-size: 18px;
  }
  
  .btn--large {
    padding: 12px 26px;
    font-size: 20px;
  }
  
  .btn--large:hover,
  .btn--medium:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
    transition: 250ms;
  } */

.hero-container {
    filter: grayscale(55%);
    background: #1DC198;
    height: 100vh;
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    object-fit: contain;
    font-family: 'Roboto', sans-serif;
    background-image: url('/images/foto-portada.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.content-container {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0px;
    width: 100vw;
    height: 100vh;
}

.text-container {
    width: 50%;
    margin-left: 50;
    text-align: left;
}

.text-container > h1 {
    flex: 1 1;
    color: white;
    font-size: 60px;
    margin-top: 1px;
    margin-left: 50px;
}

.text-container > p {
    text-align: left;
    flex: 1 1;
    margin-left: 50px;
    margin-top: 20px;
    line-height: 32px;
    color: white;
    font-size: 22px;
    font-weight: 100;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
    margin-top: 32px;
    margin-left: 50px;
    text-align: left;
    width: 40%;
}

.hero-btns .btn {
    margin: 6px;
}

.fa-play-circle {
    margin-left: 4px;
}

.info-container {
    font-size: large;
    color: #fff;
    height: 20%;
    background: transparent;
    position: relative;
    z-index: 1;
}

@media screen and (max-width: 960px) {
    .hero-container > h1 {
        font-size: 50px;
        margin-top: -50px;
    }
}

@media screen and (max-width: 768px) {
    .hero-container {
        position: relative;
        background-image: url("/images/foto-portada.jpg");
        background-repeat: no-repeat;
        background-position: center top;
        position: relative;
        height: 90vh;
        width: auto;
    }

    .text-container {
        margin-left: 10px;
        width: 80%;
    }

    .text-container > h1 {
        font-size: 50px;
        margin-top: 0;
        height: auto;
    }

    .text-container > p {
        font-size: 20px;
        margin-top: 10px;
        color: black;
        font-weight: 100;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
        
    }

    .hero-btns {
        width: 100%;
        text-decoration: none;
    }
 
}

.services-container {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 10%;
    background: linear-gradient(7deg, rgba(18, 115, 115, 1) 0%, rgba(18, 158, 156, 1) 61%);
  }
  
  .services-wrapper {
    max-width: 1000px;
    margin: 0;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    padding: 0 20px;
  }
  
  .services-card {
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    height: 280px;
    width: auto;
    padding: 35px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;
  }
  
  .services-card:hover {
    transform: scale(1.02);
    cursor: pointer;
  }
  
  .services-card > img {
    height: 160px;
    width: auto;
    margin-bottom: 10px;
  }
  
  .services-container > h2 {
    font-size: 2rem;
    color: #fff;
    
    
    padding: 5%;
    font-weight: 400;
    text-align: center;
  }

  
  .services-card > h3 {
    font-size: 1.2rem;
    margin-top: 10px;
  }
  
  .services-card > p {
    font-size: 1rem;
    font-weight: 100;
    margin-top: 10px;
  }
  
  @media screen and (min-width: 768px) {
    .services-container {
      height: auto;
    }
    .services-wrapper {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media screen and (min-width: 1000px) {
    .services-container {
      height: auto;
    }
    .services-wrapper {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      padding: 0 50px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .services-container {
      height: auto;
    }
    .services-container > h2 {
      font-size: 1.5rem;
    }
    .services-wrapper {
      padding: 0 10px;
    }
  }
  
* {
  font-family: Open Sans;
}

section {
  width: 100%;
  display: inline-block;
  background: #333;
  height: 50vh;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  text-decoration: underline;
}

.footer-distributed{
	/* background: #666; */
  background-color: #222;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
	box-sizing: border-box;
	width: 100%;
	text-align: left;
	font: bold 16px sans-serif;
	padding: 55px 50px;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right{
	display: inline-block;
	vertical-align: top;
}



.footer-distributed .footer-left{
	width: 40%;
}

.footer-distributed h3{
	color:  #ffffff;
	font: normal 36px 'Open Sans', cursive;
	margin: 0;
}

.footer-distributed h3 span{
	color:  lightseagreen;
}



.footer-distributed .footer-links{
	color:  #ffffff;
	margin: 20px 0 12px;
	padding: 0;
}

.footer-distributed .footer-links a{
	display:inline-block;
	line-height: 1.8;
  font-weight:400;
	text-decoration: none;
	color:  inherit;
}

.footer-distributed .footer-company-name{
	color:  #ffff;
	font-size: 14px;
	font-weight: normal;
	margin: 0;
}

/* Footer Center */

.footer-distributed .footer-center{
	width: 35%;
}

.footer-distributed .footer-center i{
	background-color:  #33383b;
	color: #ffffff;
	font-size: 25px;
	width: 38px;
	height: 38px;
	border-radius: 50%;
	text-align: center;
	line-height: 42px;
	margin: 10px 15px;
	vertical-align: middle;
}

.footer-distributed .footer-center i.fa-envelope{
	font-size: 17px;
	line-height: 38px;
}

.footer-distributed .footer-center p{
	display: inline-block;
	color: #ffffff;
  font-weight:400;
	vertical-align: middle;
	margin:0;
}

.footer-distributed .footer-center p span{
	display:block;
	font-weight: normal;
	font-size:14px;
	line-height:2;
}

.footer-distributed .footer-center p a{
	color:  lightseagreen;
	text-decoration: none;;
}

.footer-distributed .footer-links a:before {
  content: "|";
  font-weight:300;
  font-size: 20px;
  left: 0;
  color: #fff;
  display: inline-block;
  padding-right: 5px;
}

.footer-distributed .footer-links .link-1:before {
  content: none;
}

/* Footer Right */

.footer-distributed .footer-right{
	width: 20%;
}

.footer-distributed .footer-company-about{
	line-height: 20px;
	color:  #92999f;
	font-size: 13px;
	font-weight: normal;
	margin: 0;
}

.footer-distributed .footer-company-about span{
	display: block;
	color:  #ffffff;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 20px;
}

.footer-distributed .footer-icons{
	margin-top: 25px;
}

.footer-distributed .footer-icons a{
	display: inline-block;
	width: 35px;
	height: 35px;
	cursor: pointer;
	background-color:  #33383b;
	border-radius: 2px;

	font-size: 20px;
	color: #ffffff;
	text-align: center;
	line-height: 35px;

	margin-right: 3px;
	margin-bottom: 5px;
}

/* If you don't want the footer to be responsive, remove these media queries */

@media (max-width: 880px) {

	.footer-distributed{
		font: bold 14px sans-serif;
	}

	.footer-distributed .footer-left,
	.footer-distributed .footer-center,
	.footer-distributed .footer-right{
		display: block;
		width: 100%;
		margin-bottom: 40px;
		text-align: center;
	}

	.footer-distributed .footer-center i{
		margin-left: 0;
	}

}



/* 

.footer-container{
  background-color: #101522;
}

.footer-wrapper{
  padding: 48px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
}

.footer-links-container{
  display: flex;
  justify-content: center;
}

.footer-links-wrapper{
  display: flex;
}

.footer-link-items{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
  color: #fff;
}
.footer-link-title{
  font-size: 20px;
  margin-bottom: 16px;
  font-weight:900;
}
.footer-link{
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 16px;
  font-weight: 100;

}
.footer-link::hover{
  color: #01bf71;
  transition: 0.3s ease-out;
}



@media screen and (max-width: 820px) {
  .footer-links-container{
      padding-top: 32px;
  }
  .footer-links-wrapper{
    flex-direction: column;
  }
     
  }
  @media screen and (max-width: 420px) {
    .footer-links-wrapper{
      margin: 0;
    padding: 10px;
    width: 100%;
}
  }  */

 .info-container {
  background: linear-gradient(180deg, rgba(18, 115, 115, 1) 0%, rgba(18, 158, 156, 1) 61%);
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
}

.info-content-container {
  display: flex;
  justify-content: space-around; 
  width: 80%; 
}
.info-item {
  text-align: center;
  flex: 1 1; 
  margin: 0 20px;
}
.info-item>h3{
  padding-bottom: 6%;
}
.info-item > p{
  text-align:justify;
  flex: 1 1; 
  margin: 0 20px;
}

.info-image {
  width: 90%; 
  height: auto; 
  overflow: hidden;
  background-color: #f0f0f0;
}



.info-text {
  flex: 1 1;
  font-size: 40px;
  color: #000;
}

.info-item > img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;

 width: 55%;
  
}



.info-text > p {
  font-size: 25px;
  font-weight: 100;
}

@media screen and (max-width: 769px){
  .info-container {
    padding-top: 5%;
    height: 80vh;
    padding: 5%;
  }
  .info-content-container{
    width: 90%;

  }

  .info-item{
    padding-top: 5%;
  }
  .info-item > h3,p{
    font-size: 1rem;
  }
  

  .info-item > img{
    padding-bottom: 10%;
    height: 20%;
    width: auto;
    
  }

}



.location-container {
  display: flex;
  flex-direction: column; /* Cambia a dirección de columna en vista móvil */
  align-items: center;
  position: relative;
  background: linear-gradient(0deg, rgba(49, 208, 200, 1) 74%, rgba(98, 230, 220, 1) 58%, rgba(155, 244, 234, 1) 36%);
  font-family: 'Roboto', sans-serif;
  padding-top: 3%;
}

.location-content-container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  text-align: center;
  width: 80%; 
  padding-bottom: 4%;
  
}

.location-content-container > img {
  display: inline-block;
  float: none;
  padding: 10px;
  width: auto;
  height: 400px;
}


.location-content-container > p {
  font-weight: 500;
}



.location-container > h2{
  font-size: 2rem;
  font-weight: 600;
  width: 70%;
  text-align: center;
}


.btn-mobile {
  margin-top: 20px; 
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;

}

.btns{
  width: 20%;
}

@media screen and (max-width: 768px) {
  .location-content-container > img {
    width: 90%;
    height: auto;
  }
  .location-container > h2{
    font-size: 1.8rem;
    width: 80%;
    
  }
}


  

.about-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: linear-gradient(0deg, rgba(155, 244, 234, 1) 74%, rgba(98, 230, 220, 1) 58%, rgba(49, 208, 200, 1) 36%);

}

.about-content-container {
  display: flex;
  align-items: center;
}

.about-content-container > img {
  width: 55vh;
  height: auto;
}

.about-text-container {
  width: 45vh;
  height: auto;
  margin-left: 20px;
  line-height: 160%;
  padding: 3%;
}
.about-text-container > h3{
  padding-bottom: 10%;
  font-weight: 600;
  font-size: 1.8rem;
}
.about-text-container > p{
  padding-bottom: 10%;
}

/* Ajustes para pantallas más pequeñas (responsivo) */
@media screen and (max-width: 768px) {
  .about-content-container {
    flex-direction: column-reverse;
    align-items: center;
  }

  .about-text-container {
    max-width: 90%; /* Ocupa todo el ancho en pantallas más pequeñas */
    margin-left: 0;
    margin-top: 20px;
    padding: 7%;
  }
  .about-text-container > h3{
      padding-bottom: 3%;

  }
  .about-content-container img {
    width: 90%;
    height: auto;
  }
  .about-text-container{
    width: 90%;
  }
  
}

