.btn {
  border-radius: 50px;
  white-space: nowrap;
  outline: none;
  border: none;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

.btn--primary {
  background-color: var(--primary);
  color: #242424;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: #242424;
  color: #fff;
  padding: 8px 20px; /* Ajusta estos valores según tus necesidades */
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  color: #242424;
  transition: 250ms;
}



/* :root {
    --primary: #fff;

  }
  
  .btn {
    
    border-radius: 50px;
    white-space: nowrap;
    outline: none;
    border: none;
    
    
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    
   
  }
  
  .btn--primary {

    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
  }
  
  .btn--outline {
    background-color: #242424;
    color: #fff;
    padding: 8px 20px;
   
    transition: all 0.3s ease-out;
  }
  
  .btn--medium {
    padding: 8px 20px;
    font-size: 18px;
  }
  
  .btn--large {
    padding: 12px 26px;
    font-size: 20px;
  }
  
  .btn--large:hover,
  .btn--medium:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
    transition: 250ms;
  } */
