

.navbar {
  background: linear-gradient(90deg, rgba(18,19,23,1) 0%, rgba(50,59,66,1) 61%);
  
  height: 15vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 1.2rem;
} 

img{
  margin-top: 5px;
  height: 50px;

}
.logo-container {
  color: #fff;
  margin-left: 20px;
  cursor: pointer;
  font-size: 2rem;
  display: flex; 
  height: 100%;

}
.navbar-container{
  display: flex;
}
  
.nav-menu {
  display: flex;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  width: 70vw;
  justify-content:center;
  text-align: center;

}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
  font-size: 15px;
  color: #fff;
}


.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  text-align: center;
  font-weight: 700;
}


.nav-links:hover {
  background-color: white;
  color: black;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0; .navbar {
      background: linear-gradient(90deg, rgba(18,19,23,1) 0%, rgba(50,59,66,1) 61%);
      
      height: 15vh;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      font-size: 1.2rem;
    } 
    
    img{
      margin-top: 5px;
      height: 50px;
  
    }
    .logo-container {
      color: #fff;
      margin-left: 20px;
      cursor: pointer;
      font-size: 2rem;
      display: flex; 
      height: 100%;
  
    }
    .navbar-container{
      display: flex;
    }
      
    .nav-menu {
      display: flex;
      grid-template-columns: repeat(5, auto);
      grid-gap: 10px;
      list-style: none;
      width: 70vw;
      justify-content: flex-end;
      text-align: center;
    
    }


    
    .nav-item {
      display: flex;
      align-items: center;
      height: 80px;
      font-size: 15px;
    }
    
    
    .nav-links {
      color: white;
      text-decoration: none;
      padding: 0.5rem 1rem;
      text-align: center;
    }
    
    .nav-links:hover {
      background-color: white;
      color: black;
      border-radius: 4px;
      transition: all 0.2s ease-out;
    }
    
    .fa-bars {
      color: #fff;
    }
    
    .nav-links-mobile {
      display: none;
    }
    
    .menu-icon {
      display: none;
    }


    @media screen and (max-width: 960px) {
      .NavbarItems {
        position: relative;
      }
    
      .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
      }
    
      .nav-menu.active {
        background: #242222;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
      }
    
      .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
      }
    
    
      .nav-links:hover {
        background-color:#129e9c 61%;
        border-radius: 0;
      }
    
      .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
      }
    
      .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.2rem;
        cursor: pointer;
      }
    
      .fa-times {
        color: #fff;
        font-size: 2rem;
      }
    
      .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        background: #1888ff;
        text-decoration: none;
        color: #fff;
        font-size: 1.5rem;
      
      }
  
    .nav-item{
      font-size: 100%;
    
    }

    
      .nav-links-mobile:hover {
        background: #fff;
        color: #129e9c 61%;
        transition: 250ms;
      }
    
      button {
        display: none;
      }
    }

    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #1888ff;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.2rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  
  }

.nav-item{
  font-size: 100%;
}


  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }

  button {
    display: none;
  }
} 